.announcementSection{
    font-family:Cinzel;
    text-align: center;
    color:white;
    
    .sub-header{
        font-size:2em;
    }
    .header{
        font-size:3em;
    }
    .videoContainer { 
        position: relative; 
        padding-bottom: 53.65%; 
        padding-top: 30px; 
        height: 0; 
        overflow: hidden; 
        margin-top: 0.5em;
        margin-bottom: 1em;
    }
    .videoContainer iframe, .video-container object, .video-container embed { 
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%; 
        padding-bottom: 32px;
    }
}

.callToActionSection{
    text-align: center;
    color:white;

    iframe{
        width:100%;
        min-height: 278px;
    }

    .socialIconsContainer{
        margin-bottom:1em;
        padding-top:1em;
        padding-bottom:1em;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 1em;

        .social-icon{
            margin-left:1em;
            margin-right:1em;
        }
    }



    .emailFormContainer{
        display:flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;

    }
}

.parallaxBanner{
    margin-top:1em;
}
