#root>.header{
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    color:white;
    font-family: Cinzel ;
    font-size: 45px;
    height:80px;
    text-align: center;
    padding:12px;

    img{
        width:auto;
        height:100%;
        margin:12px;
    }

    .emailContainer{
        position:absolute;
        right: 2%;
    }
}