.emailForm{
    p {
        font-size: 1.5em;
        margin:0.25em;
    }
    .emailFormInputContainer {
        margin-top: 0.5em;

        input{
            width: 300px;
            margin-right: 8px;
            height: 24px;
            font-size: 16px;
            margin-top: 0.5em;
        }
        button{
            height: 24px;
            font-size: 16px;
            color: white;
            background: black;
            cursor: pointer;
        }
    }
}



