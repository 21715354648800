.video-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    padding-bottom: 32px;
  }
  
  .video-item {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* On mobile, display videos in a single column */
  @media (max-width: 768px) {
    .video-grid {
      grid-template-columns: 1fr;
    }
  }
  